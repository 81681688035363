import React, { createContext, useContext } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const ReCaptchaContext = createContext();

export const ReCaptchaProvider = ({ children }) => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LepVAgqAAAAAMnBxRgksyUisqC5qHRwP-jebGqC"
      language="pl"
      container={{
        element: "google-recaptcha",
      }}
    >
      {children}
      <div id="google-recaptcha" />
    </GoogleReCaptchaProvider>
  );
};

const useReCaptcha = () => useContext(ReCaptchaContext);

export { useReCaptcha };
export default ReCaptchaProvider;
